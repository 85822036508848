import React, { useMemo, useState } from 'react'
import { FaChevronLeft, FaBook } from 'react-icons/fa'

import { Stack, Flex, Button } from '@chakra-ui/react'

import DataTable from '@/components/DataTable'
import { IHeader } from '@/components/DataTable/types'
import Pagination from '@/components/Pagination'

import { IParameterSettingProps } from './types'

const pdfHeader: IHeader = {
  name: 'volumes em PDF',
  key: 'volume',
  type: 'element',
  width: '40%',
  footer: 'Total',
}

const htmlHeader: IHeader = {
  name: 'volumes em HTML',
  key: 'volume',
  type: 'element',
  width: '40%',
  footer: 'Total',
}

const tableHeader: Array<IHeader> = [
  {
    name: 'EXEMPLARES',
    key: 'copies',
    align: 'center',
    width: 'auto',
    footer: '660',
    subHeader: [
      {
        name: 'QUANT. SUBMETIDA',
        key: 'quant_submitted',
        align: 'center',
      },
    ],
  },
  {
    name: 'ITENS DE AVALIAÇÃO',
    key: 'evaluation_items',
    align: 'center',
    width: 'auto',
    footer: '30',
    subHeader: [
      {
        name: 'TOTAL DE PÁGINAS',
        key: 'total_pages',
        align: 'center',
      },
    ],
  },
  {
    name: 'TEMPO/SEG',
    key: 'time',
    type: 'element',
    align: 'center',
    width: 'auto',
    footer: '15',
    subHeader: [
      {
        name: 'leitura/pág',
        key: 'read_page',
        type: 'element',
        align: 'center',
      },
    ],
  },
]

const tableData = [
  {
    id: 1,
    volume: 'Volume 01',
    quant_submitted: 1,
    total_pages: 1,
    read_page: 1,
  },
  {
    id: 2,
    volume: 'Volume 02',
    quant_submitted: 1,
    total_pages: 1,
    read_page: 1,
  },
  {
    id: 3,
    volume: 'Volume 03',
    quant_submitted: 1,
    total_pages: 1,
    read_page: 1,
  },
  {
    id: 4,
    volume: 'Volume 04',
    quant_submitted: 1,
    total_pages: 1,
    read_page: 1,
  },
]

const ParameterSetting = ({
  goToPrevious,
  goToNext,
}: IParameterSettingProps) => {
  const [page, setPage] = useState(0)

  const pageInfo = useMemo(() => {
    const itemsPerPage = 3

    return {
      itemsPerPage,
      pageCount: 2,
      itemsCount: 5,
    }
  }, [])

  return (
    <Stack>
      <DataTable headers={[pdfHeader].concat(tableHeader)} data={tableData} />
      <DataTable headers={[htmlHeader].concat(tableHeader)} data={tableData} />
      <Pagination
        optionsItemsPerPage={[3, 5, 10]}
        itemsPerPage={pageInfo.itemsPerPage}
        pageCount={pageInfo.pageCount}
        itemsCount={pageInfo.itemsCount}
        onChangePageInfo={({ pageIndex }) => setPage(pageIndex)}
      />
      <Flex justify="space-between">
        <Button
          size="sm"
          key="go-back"
          variant="ghost"
          leftIcon={<FaChevronLeft />}
          color="brand.primary.dark_1"
          onClick={goToPrevious}
        >
          Voltar
        </Button>
        <Button
          size="sm"
          bg="brand.primary.dark_1"
          colorScheme="blue"
          color="white"
          leftIcon={<FaBook />}
          onClick={goToNext}
        >
          Dimensionar volumes{' '}
        </Button>
      </Flex>
    </Stack>
  )
}

export default ParameterSetting
