import React, { useEffect, useMemo, useState } from 'react'
import { FaCircleInfo } from 'react-icons/fa6'

import {
  Box,
  Flex,
  FormLabel,
  Input,
  Icon,
  Tooltip,
  Text,
} from '@chakra-ui/react'

import DataTable from '@/components/DataTable'
import Modal from '@/components/Modal'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'

import { dataToTable } from './exampleData'
import { tableHeaders } from './exampleData'
import { detailsModalProps } from './types'

const DetailsModal = ({ isOpen, onClose, data }: detailsModalProps) => {
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    itemsCount: dataToTable.length,
    pageCount: Math.ceil(dataToTable.length / 10),
    pageSizeOptions: [10, 20, 30, 40, 50],
  })

  const handleOnChangePageInfo = ({
    pageIndex,
    itemsPerPage,
  }: IPageInfoProps) => {
    const previousItemsPerPage = pagination.pageSize
    const adjustedPageIndex =
      itemsPerPage !== previousItemsPerPage ? 1 : pageIndex + 1

    setPagination({
      ...pagination,
      page: adjustedPageIndex,
      pageSize: itemsPerPage,
      pageCount: Math.ceil(dataToTable.length / itemsPerPage),
    })
  }

  const mapTableData = useMemo(() => {
    const start = (pagination.page - 1) * pagination.pageSize
    const end = start + pagination.pageSize
    const paginatedData = dataToTable.slice(start, end)

    return paginatedData.map(item => {
      return {
        ...item,
        formato: (
          <Flex gap="1" direction="row">
            <Text>{item.formato}</Text>
            <Tooltip
              hasArrow
              label="Informação"
              bg="brand.neutral.dark_2"
              color="white"
              placement="bottom"
            >
              <Box>
                <Icon as={FaCircleInfo} />
              </Box>
            </Tooltip>
          </Flex>
        ),
      }
    })
  }, [dataToTable, pagination])

  return (
    <Modal
      key={`details-modal-${data.id}`}
      title="Detalhes da coleção"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="3xl"
    >
      <Box w="full" h="full" gap="4">
        <Flex direction="row" gap="4">
          <Box w="50%">
            <FormLabel fontSize="sm">Edital</FormLabel>
            <Input
              placeholder="Placeholder"
              disabled
              bg="brand.neutral.light_2"
              borderColor="brand.neutral.light_2"
              _placeholder={{ fontStyle: 'italic' }}
            />
          </Box>
          <Box w="50%">
            <FormLabel fontSize="sm">Objeto</FormLabel>
            <Input
              placeholder="Placeholder"
              disabled
              bg="brand.neutral.light_2"
              borderColor="brand.neutral.light_2"
              _placeholder={{ fontStyle: 'italic' }}
            />
          </Box>
        </Flex>

        <Flex direction="row">
          <Box w="100%">
            <FormLabel fontSize="sm">Editora</FormLabel>
            <Input
              placeholder={data.original?.editora}
              disabled
              bg="brand.neutral.light_2"
              borderColor="brand.neutral.light_2"
              _placeholder={{ fontStyle: 'italic' }}
            />
          </Box>
        </Flex>

        <Flex direction="row" gap="4">
          <Box w="50%">
            <FormLabel fontSize="sm">Código da Coleção</FormLabel>
            <Input
              placeholder={data.original?.cod_colecao}
              disabled
              bg="brand.neutral.light_2"
              borderColor="brand.neutral.light_2"
              _placeholder={{ fontStyle: 'italic' }}
            />
          </Box>
          <Box w="50%">
            <FormLabel fontSize="sm">Coleção</FormLabel>
            <Input
              placeholder="Placeholder"
              disabled
              bg="brand.neutral.light_2"
              borderColor="brand.neutral.light_2"
              _placeholder={{ fontStyle: 'italic' }}
            />
          </Box>
        </Flex>
      </Box>

      <Box>
        <DataTable
          rowId="volume"
          key="table-details-modal"
          data={mapTableData}
          headers={tableHeaders}
          selectableRow={false}
        />

        <Pagination
          pageIndex={pagination.page - 1}
          optionsItemsPerPage={pagination.pageSizeOptions}
          itemsPerPage={pagination.pageSize}
          pageCount={pagination.pageCount}
          itemsCount={pagination.itemsCount}
          onChangePageInfo={handleOnChangePageInfo}
        />
      </Box>
    </Modal>
  )
}

export default DetailsModal
