import React, { useEffect, useMemo, useState } from 'react'
import { FaFileImport, FaRegEdit } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { SearchIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, IconButton, Text, VStack } from '@chakra-ui/react'

import { TGetObjectsResponse } from '@/api/objects/types'
import DataFilter, { IFilter } from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import { IHeader } from '@/components/DataTable/types'
import Pagination from '@/components/Pagination'
import Tag from '@/components/Tag'
import { statusObject } from '@/components/Tag/StatusObject/types'

const EditButton: React.FC<{ id: number }> = ({ id }) => {
  const handleEdit = () => {
    // TODO: Implementar lógica de edição
  }

  return (
    <IconButton
      aria-label="Editar objeto"
      variant="ghost"
      size="sm"
      icon={<FaRegEdit size="20px" />}
      color="brand.primary.dark_1"
      onClick={handleEdit}
    />
  )
}

interface IObjectsProps {
  objects: TGetObjectsResponse
}

const Objects: React.FC<IObjectsProps> = ({ objects }) => {
  const navigate = useNavigate()

  const randomStatus = (index: number) => {
    const statuses: statusObject[] = [
      'finished',
      'active',
      'inactive',
      'waiting_bond',
    ]
    return statuses[index % statuses.length]
  }

  const handleImportData = () => {
    navigate('importar')
  }

  const data = useMemo(() => {
    if (!objects) return []
    return objects.map((object, index) => ({
      id: object.coObject,
      objeto: (
        <VStack justify="flex-start">
          <Box w="full">
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              {object.coObject.toString() || 'Não informado'}
            </Text>
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              2024-2027
            </Text>
          </Box>
        </VStack>
      ),
      fase: object.dsStage || 'Não informado',
      colecoes: object.nuCollections.toString() || '0',
      volumes: object.nuVolumes.toString() || '0',
      status: <Tag group="statusObjects" value={randomStatus(index)} />,
      edit: <EditButton id={object.coObject} />,
    }))
  }, [objects])

  const headers: Array<IHeader> = useMemo(
    () => [
      { name: 'Objeto', key: 'objeto', align: 'center', type: 'element' },
      { name: 'Fase', key: 'fase', align: 'center' },
      { name: 'Quant. de coleções', key: 'colecoes', align: 'center' },
      { name: 'Quant. de volumes', key: 'volumes', align: 'center' },
      {
        name: 'Status',
        key: 'status',
        type: 'element',
        align: 'center',
      },
      { name: '', key: 'edit', type: 'element', align: 'center' },
    ],
    []
  )

  const filters: Array<IFilter> = [
    {
      name: 'filter1',
      label: 'Filter 1',
      type: 'text',
      icon: <SearchIcon />,
    },
    {
      name: 'filter2',
      label: 'Filter 2',
      type: 'text',
      icon: <SearchIcon />,
    },
    {
      name: 'filter3',
      label: 'Filter 3',
      type: 'select',
      options: [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
      ],
    },
  ]

  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
    itemsCount: 0,
    pageCount: 1,
    pageSizeOptions: [10, 20, 30, 40, 50],
  })

  useEffect(() => {
    const itemsCount = data.length
    const pageCount = Math.ceil(itemsCount / pagination.pageSize)

    setPagination(prev => ({
      ...prev,
      itemsCount,
      pageCount,
    }))
  }, [data, pagination.pageSize])

  return (
    <Flex gap={6} direction="column">
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        borderRadius="md"
        boxShadow="md"
        p={4}
      >
        <DataFilter
          testId="data-filter"
          filters={filters}
          canPrint={true}
          canDownload={true}
          onChangeFilter={() => {}}
        />
      </Flex>
      <Flex
        borderRadius="md"
        boxShadow="md"
        direction="column"
        gap={4}
        p={5}
        w="full"
        sx={{
          '@media (max-width: 600px)': {
            flexDirection: 'column',
            'th, td': {
              whiteSpace: 'nowrap',
            },
          },
        }}
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          sx={{
            '@media (max-width: 500px)': {
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              'th, td': {
                whiteSpace: 'nowrap',
              },
            },
          }}
        >
          <Text
            fontSize="2xl"
            mb="4"
            color="brand.primary.dark_1"
            fontWeight="semibold"
          >
            Gestão de Objetos
          </Text>
          <Button
            leftIcon={<FaFileImport />}
            backgroundColor="brand.primary.dark_1"
            color="white"
            py={2}
            px={6}
            borderRadius="md"
            fontSize="md"
            fontWeight="medium"
            height="32px"
            _hover={{
              bg: 'brand.primary.dark_2',
            }}
            onClick={handleImportData}
          >
            Importar Objetos
          </Button>
        </Flex>

        <DataTable data={data} headers={headers} />

        <Pagination
          pageIndex={pagination.page}
          optionsItemsPerPage={pagination.pageSizeOptions}
          itemsPerPage={pagination.pageSize}
          itemsCount={pagination.itemsCount}
          pageCount={pagination.pageCount}
          onChangePageInfo={({ pageIndex, itemsPerPage }) => {
            setPagination(prev => ({
              ...prev,
              page: pageIndex,
              pageSize: itemsPerPage,
            }))
          }}
        />
      </Flex>
    </Flex>
  )
}

export default Objects
