import React, { useEffect, useState, useCallback } from 'react'

import {
  Text,
  Tabs as ChakraTabs,
  TabList as ChakraTabList,
  Tab as ChakraTab,
  TabPanels as ChakraTabPanels,
  TabPanel as ChakraTabPanel,
  useColorModeValue,
} from '@chakra-ui/react'

import { findIndexWithDefault } from '@/utils/array'

import { ITabsProps } from './types'

const Tabs: React.FC<ITabsProps> = ({
  items,
  initialTab,
  onTabClick,
  variant = 'unstyled',
  ...rest
}) => {
  const textColor = useColorModeValue('gray.700', 'gray.50')
  const borderColor = useColorModeValue('gray.200', 'gray.50')
  const selectedColor = 'brand.primary.base'
  const [selectedTab, setSelectedTab] = useState<number>(0)

  useEffect(() => {
    if (initialTab) {
      const index = findIndexWithDefault(
        items,
        item => item.id === initialTab,
        0
      )
      setSelectedTab(index)
    }
  }, [initialTab, items])

  const handleOnChange = useCallback(
    (index: number) => {
      if (onTabClick) {
        onTabClick(items[index].id)
        return
      }

      setSelectedTab(index)
    },
    [items, onTabClick]
  )

  return (
    <ChakraTabs
      variant={variant}
      width="100%"
      onChange={handleOnChange}
      index={selectedTab}
      {...rest}
    >
      <ChakraTabList borderBottom="2px" borderColor={borderColor}>
        {items.map(item => (
          <ChakraTab
            key={`tab-${item.id}`}
            color={textColor}
            _selected={{
              color: selectedColor,
              marginBottom: '-2px',
              borderBottom: '2px',
            }}
          >
            <Text fontSize="md">{item.label}</Text>
          </ChakraTab>
        ))}
      </ChakraTabList>
      <ChakraTabPanels>
        {items.map(item => {
          return (
            <ChakraTabPanel key={`tab-panel-${item.id}`}>
              {item.render ? item.render() : null}
            </ChakraTabPanel>
          )
        })}
      </ChakraTabPanels>
    </ChakraTabs>
  )
}

export default Tabs
