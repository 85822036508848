import React from 'react'
import { FaUpload, FaFileImport, FaQuestion } from 'react-icons/fa6'

import { Button, Flex, Icon, Stack, Text } from '@chakra-ui/react'

import { SelectImportProps } from './types'

const SelectImport = ({
  activeStep,
  setActiveStep,
  setSelectedType,
}: SelectImportProps) => {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      gap="8"
      w="full"
      h="full"
      py="60px"
    >
      <Icon
        as={FaQuestion}
        boxSize="128px"
        color="#fff"
        rotate="30deg"
        bg="brand.primary.dark_1"
        borderRadius="full"
        p="4"
      />
      <Stack align="center" spacing="8">
        <Text
          color="brand.primary.dark_2"
          textAlign="center"
          fontSize="20px"
          fontWeight="700"
          lineHeight="120%"
        >
          Qual o modo de importação você deseja?
        </Text>

        <Flex direction={{ base: 'column', sm: 'row' }} gap="4">
          <Button
            color="white"
            bg="brand.primary.dark_1"
            _hover={{ bg: 'brand.primary.dark_2' }}
            gap="2"
            py="2"
            px="6"
            borderRadius="4"
            leftIcon={<Icon as={FaUpload} />}
            onClick={() => {
              setSelectedType('manual')
              setActiveStep(activeStep + 1)
            }}
          >
            Manual
          </Button>
          <Button
            color="white"
            bg="brand.primary.dark_1"
            _hover={{ bg: 'brand.primary.dark_2' }}
            gap="2"
            py="2"
            px="6"
            borderRadius="4"
            leftIcon={<Icon as={FaFileImport} />}
            onClick={() => {
              setSelectedType('automatic')
              setActiveStep(activeStep + 1)
            }}
          >
            Automática
          </Button>
        </Flex>
      </Stack>
    </Flex>
  )
}

export default SelectImport
