import React, { useEffect } from 'react'
import { BsFillPencilFill } from 'react-icons/bs'
import { FaCheckCircle, FaChevronLeft } from 'react-icons/fa'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { Button, Flex, Heading, useColorMode } from '@chakra-ui/react'

import Tabs from '@/components/Tabs'
import { ITabItem } from '@/components/Tabs/types'
import { ProfileProvider } from '@/contexts/ProfileContext/ProfileContext'

import { useProfileController } from './controller'
import { ModalProfile } from './Modal'
import S from './styles'
const Profile = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const {
    userData,
    dataMyAccount,
    isAdmin,
    isLoadingSpecialNeeds,
    isErrorSpecialNeeds,
    specialNeeds,
    countries,
    states,
    cities,
    setSelectedCountry,
    setSelectedState,
    setSelectedCity,
    selectedCity,
    selectedCountry,
    selectedState,
    isOpened,
    setIsOpened,
    handleOnSubmit,
    onSubmit,
    submitFormikRef,
    dataLvlSchool,
    isLoadingCities,
    isLoadingStates,
  } = useProfileController()

  const initialPath = location.pathname.split('/').pop()

  const tabs: Array<ITabItem> = [
    {
      id: 'informacoes-pessoais',
      label: 'Informações pessoais',
    },
    {
      id: 'vinculos',
      label: 'Vínculos',
    },
  ]

  const handleTabClick = (id: string) => {
    navigate(`${id}`)
  }

  useEffect(() => {
    handleTabClick('informacoes-pessoais')
  }, [])

  return (
    <Flex direction="column">
      <Flex
        direction="column"
        padding="5"
        gap="12"
        borderRadius="0.3125rem"
        boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
      >
        <Heading as="h1" size="lg" fontWeight="400" lineHeight="120%">
          Meu Perfil
        </Heading>

        <Flex
          color="white"
          borderRight="1px"
          padding="6"
          gap="6"
          align={{ base: 'center' }}
          direction={{ base: 'column', md: 'row' }}
          justify={{ base: 'center' }}
          marginBottom={2}
        >
          <S.TopContainer>
            <S.AvatarContainer>
              <S.StyledAvatar name={userData?.noUserName} />
              <>
                <S.EditAvatarButton onClick={() => setIsOpened(true)}>
                  <S.EditAvatarIcon as={BsFillPencilFill} />
                </S.EditAvatarButton>
              </>
            </S.AvatarContainer>

            <S.UserInfo>
              <S.UserNameText color="brand.primary.dark_1">
                {userData?.noUserName}
              </S.UserNameText>
              <S.UserEmailText color="gray.700">
                {userData?.dsGovbrEmail}
              </S.UserEmailText>
            </S.UserInfo>
          </S.TopContainer>
        </Flex>
        <Tabs
          items={tabs}
          initialTab={initialPath}
          onTabClick={handleTabClick}
        />
        <ProfileProvider
          value={{
            userData,
            dataMyAccount,
            isAdmin,
            isLoadingSpecialNeeds,
            isErrorSpecialNeeds,
            specialNeeds,
            countries,
            states,
            cities,
            setSelectedCountry,
            setSelectedState,
            setSelectedCity,
            selectedCity,
            selectedCountry,
            selectedState,
            isOpened,
            setIsOpened,
            onSubmit,
            submitFormikRef,
            dataLvlSchool,
            isLoadingCities,
            isLoadingStates,
          }}
        >
          <Outlet />
        </ProfileProvider>
        <ModalProfile
          isOpen={isOpened}
          onClose={() => {
            setIsOpened(false)
          }}
        />

        <Flex direction="row" justifyContent="flex-end" gap="2" marginTop="10">
          <Button
            size="sm"
            leftIcon={<FaChevronLeft />}
            variant="ghost"
            fontSize="sm"
            color="brand.primary.dark_1"
          >
            Voltar
          </Button>
          <Button
            size="sm"
            leftIcon={<FaCheckCircle />}
            fontSize="sm"
            color="white"
            bg="brand.primary.dark_1"
            _hover={{
              bg: 'brand.primary.dark_2',
            }}
            onClick={handleOnSubmit}
          >
            Salvar alterações
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Profile
