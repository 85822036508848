import React, { useEffect, useMemo, useState } from 'react'
import { FaPlusCircle } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  Box,
  Flex,
  Stack,
  Text,
  Button,
  useDisclosure,
  useToast,
  Skeleton,
} from '@chakra-ui/react'

import {
  useQueryPermissionsGroup,
  useQueryPermissionsGroups,
} from '@/api/user/permissions/queries'
import CardsContainer from '@/components/Cards/Container/CardsContainer'
import { ICardComponentProps } from '@/components/Cards/Container/types'
import DataFilter from '@/components/DataFilter'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'
import Tabs from '@/components/Tabs'

import PermissionModalGroup from './parts/PermissionModalGroup/PermissionModalGroup'
import { IPermissionGroupData } from './parts/types'

const Permissions = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const location = useLocation()
  const initialPath = location.pathname.split('/').pop()
  const {
    isOpen: isOpenCreate,
    onClose: onCloseCreate,
    onOpen: onOpenCreate,
  } = useDisclosure()
  const {
    isOpen: isOpenCopy,
    onClose: onCloseCopy,
    onOpen: onOpenCopy,
  } = useDisclosure()

  const [selectedPermission, setSelectedPermission] =
    useState<IPermissionGroupData>()
  const [selectedPermissionId, setSelectedPermissionId] = useState<
    string | null
  >(null)
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 5,
    itemsCount: 0,
    pageCount: 1,
    pageSizeOptions: [5, 10, 15],
  })

  const {
    data: permissions = {
      pagination: {
        count: 0,
        totalPages: 1,
        currentPage: 1,
        pageSize: 10,
      },
      results: [],
    },
    isFetching: isPermissionsLoading,
  } = useQueryPermissionsGroups(
    {
      page: pagination.page,
      pageSize: pagination.pageSize,
    },
    {
      onSuccess: data => {
        setPagination({
          ...pagination,
          page: data.pagination.currentPage,
          pageSize: data.pagination.pageSize,
          itemsCount: data.pagination.count,
          pageCount: data.pagination.totalPages,
        })
      },
      onError: () => {
        toast({
          title: 'Erro ao buscar permissões',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    }
  )

  const { refetch: fetchPermission } = useQueryPermissionsGroup(
    {
      id: String(selectedPermissionId),
    },
    {
      enabled: !!selectedPermissionId,
      onSuccess: data => {
        setSelectedPermission({
          groupName: data.name,
          description: data.description,
        })
        onOpenCopy()
      },
      onError: () => {
        toast({
          title: 'Houve um erro ao copiar a permissão. Tente novamente.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    }
  )

  useEffect(() => {
    if (selectedPermissionId) {
      fetchPermission()
    }
  }, [selectedPermissionId])

  const permissionsData: Array<ICardComponentProps> = useMemo(() => {
    return permissions.results.map(permission => ({
      id: permission.id.toString(),
      title: permission.name,
      description: permission.description,
      status: {
        tag: permission.dsStatus ? 'active' : 'inactive',
      },
    }))
  }, [permissions])

  const tabs = [
    {
      id: 'usuario',
      label: 'Usuário',
    },
    {
      id: 'equipe',
      label: 'Equipe',
    },
    {
      id: 'permissoes',
      label: 'Permissões',
    },
  ]

  const handleTabClick = (id: string) => {
    navigate(`/configuracoes/${id}`)
  }

  const handleEditPermission = (id: string) => {
    navigate(`detalhes/${id}`)
  }

  const handleOnChangePageInfo = ({
    pageIndex,
    itemsPerPage,
  }: IPageInfoProps) => {
    const previousItemsPerPage = pagination.pageSize
    const adjustedPageIndex =
      itemsPerPage !== previousItemsPerPage ? 1 : pageIndex + 1

    setPagination({
      ...pagination,
      page: adjustedPageIndex,
      pageSize: itemsPerPage,
    })
  }

  return (
    <>
      <Tabs items={tabs} initialTab={initialPath} onTabClick={handleTabClick} />
      <Stack gap={6}>
        <Box p={4} boxShadow="md">
          <DataFilter
            testId="data-filter"
            onlySearch
            filters={[]}
            onChangeFilter={() => {}}
          />
        </Box>
        <Flex
          align="center"
          justify="space-between"
          p={5}
          boxShadow="md"
          flexDirection={{
            base: 'column',
            md: 'column',
            lg: 'row',
            xl: 'row',
          }}
        >
          <Text fontSize="xl" fontWeight="bold" color="brand.primary.dark_1">
            Grupos de Permissões
          </Text>
          <Button
            leftIcon={<FaPlusCircle />}
            color="white"
            bg="brand.primary.dark_1"
            _hover={{
              bg: 'brand.primary.dark_2',
            }}
            _active={{
              bg: 'brand.primary.base',
            }}
            onClick={onOpenCreate}
          >
            Criar novo grupo de permissões
          </Button>
        </Flex>
        <Skeleton
          isLoaded={!isPermissionsLoading}
          minH="200px"
          borderRadius="xl"
        >
          <CardsContainer
            initialCards={permissionsData}
            onCopy={id => setSelectedPermissionId(id)}
            onEdit={id => handleEditPermission(id)}
          />
        </Skeleton>
        <Box p={5} boxShadow="md">
          <Pagination
            pageIndex={pagination.page - 1}
            optionsItemsPerPage={pagination.pageSizeOptions}
            itemsPerPage={pagination.pageSize}
            pageCount={pagination.pageCount}
            itemsCount={pagination.itemsCount}
            onChangePageInfo={handleOnChangePageInfo}
          />
        </Box>
        <PermissionModalGroup
          isOpen={isOpenCreate}
          onClose={onCloseCreate}
          mode="create"
        />
        <PermissionModalGroup
          isOpen={isOpenCopy}
          onClose={onCloseCopy}
          mode="duplicate"
          initialData={selectedPermission}
        />
      </Stack>
    </>
  )
}

export default Permissions
