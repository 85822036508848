import React from 'react'
import { BsCheckCircle, BsXCircle } from 'react-icons/bs'
import { FaChevronLeft } from 'react-icons/fa'

import { Box, Button, Flex, Icon, Text } from '@chakra-ui/react'

import FileUpload from '@/components/FileUpload'
import Modal from '@/components/Modal'

import { IModalProps } from './types'

export const ModalProfile: React.FC<IModalProps> = ({ isOpen, onClose }) => {
  return (
    <Modal title="Editar foto de perfil" isOpen={isOpen} onClose={onClose}>
      <Box
        borderTopWidth={2}
        borderBottomWidth={2}
        padding={2}
        borderColor="gray.200"
      >
        <Text fontSize="sm" color="gray.600" marginBottom={2}>
          Foto do perfil{' '}
          <Text as="span" color="red.500">
            *
          </Text>
        </Text>
        <FileUpload
          name="file"
          id="file"
          helperText="Só aceitamos imagens (.png ou .jpg)"
          acceptedFileTypes={['image/*']}
          maxFileSize={10}
        />
      </Box>
      <Flex
        padding={2}
        justify="flex-end"
        gap={2}
        flexDirection={{
          base: 'column',
          sm: 'row',
        }}
      >
        <Button
          type="button"
          color="#475671"
          bg="white"
          size="sm"
          _hover={{ bg: '#EDF5FF' }}
          leftIcon={<Icon as={FaChevronLeft} />}
          onClick={onClose}
        >
          Voltar
        </Button>
        <Button
          key="go-back"
          size="sm"
          leftIcon={<BsXCircle />}
          color="white"
          bg="#C53030"
          _hover={{
            bg: '#8B0A03',
          }}
          onClick={onClose}
        >
          Remover foto
        </Button>
        <Button
          key="confirm"
          variant="ghost"
          size="sm"
          leftIcon={<BsCheckCircle />}
          bg="#475671"
          color="white"
          _hover={{
            bg: '#0b2d63',
          }}
          onClick={onClose}
        >
          Salvar foto
        </Button>
      </Flex>
    </Modal>
  )
}
