import React, { useMemo } from 'react'
import { FaCircleCheck, FaChevronLeft } from 'react-icons/fa6'

import { Box, Flex, Icon, Text, Button, useDisclosure } from '@chakra-ui/react'

import DataTable from '@/components/DataTable'

import { dataToTable } from './exampleData'
import { tableHeaders } from './exampleData'
import ConfirmModal from './parts/confirmModal'
import DetailsModal from './parts/detailsModal'
import { VerifyImportProps } from './types'

const VerifyImport = ({
  activeStep,
  setActiveStep,
  data,
}: VerifyImportProps) => {
  const [selectedRow, setSelectedRow] = React.useState({} as any)

  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure()

  const {
    isOpen: isDetailsOpen,
    onOpen: onDetailsOpen,
    onClose: onDetailsClose,
  } = useDisclosure()

  const handleConfirm = () => {
    onConfirmOpen()
  }

  const mapTableData = useMemo(() => {
    return dataToTable.map(item => {
      return {
        ...item,
      }
    })
  }, [dataToTable])

  return (
    <Flex
      direction="column"
      align="center"
      justify="space-between"
      w="full"
      h="full"
    >
      <Flex
        direction="column"
        align="center"
        justify="center"
        w="100%"
        gap="4"
        py="60px"
      >
        <Text
          color="brand.primary.dark_2"
          textAlign="center"
          fontSize="20px"
          fontWeight="700"
          lineHeight="4"
        >
          Verifique os dados do objeto
        </Text>
        <Box>
          <Text fontSize="12px" fontWeight="400" lineHeight="18px">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industrys standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.
          </Text>

          <DataTable
            rowId="cod_colecao"
            key="table-verify-import"
            data={mapTableData}
            headers={tableHeaders}
            selectableRow={false}
            onRowClick={row => {
              setSelectedRow(row)
              onDetailsOpen()
            }}
          />
        </Box>
      </Flex>

      <Flex direction="row" justify="space-between" w="full">
        <Button
          color="brand.primary.dark_1"
          bg="white"
          _hover={{ bg: 'brand.primary.dark_2' }}
          gap="2"
          py="2"
          px="6"
          borderRadius="4"
          leftIcon={<Icon as={FaChevronLeft} />}
          onClick={() => setActiveStep(activeStep - 1)}
        >
          Voltar
        </Button>

        <Button
          color="white"
          bg="brand.success.base"
          _hover={{ bg: 'brand.success.dark' }}
          gap="2"
          py="2"
          px="6"
          borderRadius="4"
          leftIcon={<Icon as={FaCircleCheck} />}
          onClick={() => handleConfirm()}
        >
          Importar objeto
        </Button>
      </Flex>
      <ConfirmModal
        isOpen={isConfirmOpen}
        onClose={onConfirmClose}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
      <DetailsModal
        isOpen={isDetailsOpen}
        onClose={onDetailsClose}
        data={selectedRow}
      />
    </Flex>
  )
}

export default VerifyImport
