import React, { useState } from 'react'

import { Box, Select, useColorMode, useToast } from '@chakra-ui/react'

import { IGetAccountDTO } from '@/DTOs/user/account.dto'

import S from './styles'

const PersonalLink: React.FC = () => {
  const [userData, setUserData] = useState<IGetAccountDTO>()

  const isAdmin = userData?.ncoProfile[0] === 1

  return (
    <Box width="100%">
      <S.Row>
        <S.StyledColumn>
          <S.StyledFormLabel>
            <S.LabelText>Instituição</S.LabelText>
          </S.StyledFormLabel>
          <S.StyledInput
            placeholder="Nome"
            value={userData?.noUserName || ''}
            required
          />
          <S.InfoText>Obrigatório</S.InfoText>
        </S.StyledColumn>

        <S.StyledColumn>
          <S.StyledColumn>
            <S.StyledFormLabel>
              <S.LabelText>Cargo</S.LabelText>
            </S.StyledFormLabel>
            <S.StyledInput
              variant="filled"
              placeholder="Digite o cargo"
              value={userData?.dsRolePositionJob || ''}
            />
          </S.StyledColumn>
          <S.InfoText>Obrigatório</S.InfoText>
        </S.StyledColumn>
      </S.Row>
      <S.Row>
        <S.StyledColumn>
          <S.StyledFormLabel>
            <S.LabelText>Data de nascimento</S.LabelText>
          </S.StyledFormLabel>
          <S.StyledInput
            type="date"
            value={userData?.dtBirthDate || ''}
            readOnly={isAdmin}
            disabled={isAdmin}
          />
          <S.InfoText>Obrigatório</S.InfoText>
        </S.StyledColumn>
        <S.StyledColumn>
          <S.StyledFormLabel>
            <S.LabelText>Data de nascimento</S.LabelText>
          </S.StyledFormLabel>
          <S.StyledInput
            type="date"
            value={userData?.dtBirthDate || ''}
            readOnly={isAdmin}
            disabled={isAdmin}
          />
          <S.InfoText>Obrigatório</S.InfoText>
        </S.StyledColumn>
      </S.Row>
    </Box>
  )
}

export default PersonalLink
