import { IHeader } from '@/components/DataTable/types'

export const dataToTable = [
  {
    volume: 'AL LE 000 000 0000 PP00 00 00 000 000',
    versao: 'Descaracterizada',
    exemplar: 'Liv. Estudante',
    formato: 'Impresso',
  },

  {
    volume: 'AL LE 000 000 0000 PP00 00 00 000 001',
    versao: 'Caracterizada',
    exemplar: 'Liv. Professor',
    formato: 'PDF',
  },

  {
    volume: 'AL LE 000 000 0000 PP00 00 00 000 002',
    versao: 'Descaracterizada',
    exemplar: 'Liv. Estudante',
    formato: 'Áudiolivro',
  },

  {
    volume: 'AL LE 000 000 0000 PP00 00 00 000 003',
    versao: 'Caracterizada',
    exemplar: 'Liv. Professor',
    formato: 'PDF',
  },

  {
    volume: 'AL LE 000 000 0000 PP00 00 00 000 005',
    versao: 'Descaracterizada',
    exemplar: 'Liv. Estudante',
    formato: 'Impresso',
  },

  {
    volume: 'AL LE 000 000 0000 PP00 00 00 000 006',
    versao: 'Caracterizada',
    exemplar: 'Liv. Professor',
    formato: 'PDF',
  },

  {
    volume: 'AL LE 000 000 0000 PP00 00 00 000 007',
    versao: 'Descaracterizada',
    exemplar: 'Liv. Estudante',
    formato: 'Áudiolivro',
  },

  {
    volume: 'AL LE 000 000 0000 PP00 00 00 000 008',
    versao: 'Caracterizada',
    exemplar: 'Liv. Professor',
    formato: 'PDF',
  },

  {
    volume: 'AL LE 000 000 0000 PP00 00 00 000 009',
    versao: 'Descaracterizada',
    exemplar: 'Liv. Estudante',
    formato: 'Impresso',
  },

  {
    volume: 'AL LE 000 000 0000 PP00 00 00 000 010',
    versao: 'Caracterizada',
    exemplar: 'Liv. Professor',
    formato: 'PDF',
  },

  {
    volume: 'AL LE 000 000 0000 PP00 00 00 000 011',
    versao: 'Descaracterizada',
    exemplar: 'Liv. Estudante',
    formato: 'Áudiolivro',
  },

  {
    volume: 'AL LE 000 000 0000 PP00 00 00 000 012',
    versao: 'Caracterizada',
    exemplar: 'Liv. Professor',
    formato: 'PDF',
  },

  {
    volume: 'AL LE 000 000 0000 PP00 00 00 000 013',
    versao: 'Descaracterizada',
    exemplar: 'Liv. Estudante',
    formato: 'Impresso',
  },

  {
    volume: 'AL LE 000 000 0000 PP00 00 00 000 014',
    versao: 'Caracterizada',
    exemplar: 'Liv. Professor',
    formato: 'PDF',
  },

  {
    volume: 'AL LE 000 000 0000 PP00 00 00 000 015',
    versao: 'Descaracterizada',
    exemplar: 'Liv. Estudante',
    formato: 'Áudiolivro',
  },

  {
    volume: 'AL LE 000 000 0000 PP00 00 00 000 016',
    versao: 'Caracterizada',
    exemplar: 'Liv. Professor',
    formato: 'PDF',
  },
]

export const tableHeaders: Array<IHeader> = [
  {
    name: 'Volume',
    key: 'volume',
    type: 'element',
    width: 'auto',
  },
  {
    name: 'Versão',
    key: 'versao',
    type: 'element',
    width: 'auto',
  },
  {
    name: 'Exemplar',
    key: 'exemplar',
    type: 'element',
    width: 'auto',
  },
  {
    name: 'Formato',
    key: 'formato',
    type: 'element',
    width: 'auto',
  },
]
