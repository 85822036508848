import http from '@/api/http'

import {
  TGetUsersResponse,
  TGetUserParams,
  TGetUserResponse,
  TCreateUserInBatchParams,
  TCreateUserInBatchResponse,
  TRenewalUsersParams,
  TRenewalUserResponse,
  TUnlinkUserParams,
  TUnlinkUserResponse,
  TGetUsersPaginatedParams,
  TGetUsersPaginatedResponse,
  TGetUserProfileNameResponses,
  TGetMeResponse,
} from './types'

export const getUsers = async () => {
  const response = await http.get<TGetUsersResponse>('/users/management/')

  return response.data
}

export const getUsersPaginated = async ({
  page = 1,
  pageSize = 10,
}: TGetUsersPaginatedParams) => {
  const response = await http.get<TGetUsersPaginatedResponse>(
    `/users/management/?page=${page}&page_size=${pageSize}`
  )

  return response.data
}

export const getUser = async ({ coUser }: TGetUserParams) => {
  const response = await http.get<TGetUserResponse>(
    `/users/management/${coUser}/`
  )

  return response.data
}

export const editUser = async ({ coUser, params }: any) => {
  const response = await http.put<TGetUserResponse>(
    `/users/management/${coUser}/`,
    params
  )

  return response.data
}

export const postUserPreRegistration = async (
  users: TCreateUserInBatchParams
) => {
  const response = await http.post<TCreateUserInBatchResponse>(
    '/users/management/pre_registration/',
    users
  )

  return response.data
}

export const postRenewalUser = async (
  params: TRenewalUsersParams
): Promise<TRenewalUserResponse> => {
  const response = await http.post<TRenewalUserResponse>(
    '/users/management/renewal/',
    params
  )

  return response.data
}

export const postUnlinkUser = async (
  params: TUnlinkUserParams
): Promise<TUnlinkUserResponse> => {
  const response = await http.post<TUnlinkUserResponse>(
    '/users/management/unlink/',
    params
  )

  return response.data
}

export const getProfileNames = async () => {
  const response = await http.get<TGetUserProfileNameResponses>(
    '/users/profile/'
  )

  return response.data
}

export const getMyAccount = async ({ coUser }: TGetUserParams) => {
  const response = await http.get<TGetUserResponse>(
    `/users/myaccount/${coUser}/`
  )

  return response.data
}

export const editMyAccount = async ({ coUser, params }: any) => {
  const response = await http.patch<TGetUserResponse>(
    `/users/myaccount/${coUser}/`,
    params
  )

  return response.data
}

export const getMe = async () => {
  const response = await http.get<TGetMeResponse>('/users/management/getme/')

  return response.data
}
