import { AxiosError } from 'axios'

import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { IErrorDTO } from '@/DTOs/errorDTO'

import {
  postUserPreRegistration,
  postRenewalUser,
  postUnlinkUser,
  editUser,
  editMyAccount,
} from './services'
import {
  TCreateUserInBatchParams,
  TCreateUserInBatchResponse,
  TRenewalUsersParams,
  TRenewalUserResponse,
  TUnlinkUserParams,
  TUnlinkUserResponse,
} from './types'

export const useMutationPreRegistration = (
  options?: UseMutationOptions<
    TCreateUserInBatchResponse,
    IErrorDTO,
    TCreateUserInBatchParams
  >
) => {
  return useMutation(postUserPreRegistration, options)
}

export const useMutationRenewalUsers = (
  options?: UseMutationOptions<
    TRenewalUserResponse,
    AxiosError<IErrorDTO>,
    TRenewalUsersParams
  >
) => {
  return useMutation(postRenewalUser, options)
}

export const useMutationUnlinkUsers = (
  options?: UseMutationOptions<
    TUnlinkUserResponse,
    AxiosError<IErrorDTO>,
    TUnlinkUserParams
  >
) => {
  return useMutation(postUnlinkUser, options)
}

export const useMutationEditUser = (
  options?: UseMutationOptions<any, AxiosError<IErrorDTO>, TUnlinkUserParams>
) => {
  return useMutation(editUser, options)
}

export const useMutationEditMyAccount = (
  options?: UseMutationOptions<any, AxiosError<IErrorDTO>, any>
) => {
  return useMutation(editMyAccount, options)
}
