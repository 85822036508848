import React from 'react'

import { Field, ErrorMessage } from 'formik'

import {
  Text,
  FormControl,
  FormLabel,
  FormHelperText,
  Input as ChakraInput,
} from '@chakra-ui/react'

import { IInputProps } from './types'

export const Input = ({
  name,
  label,
  type,
  placeholder,
  helperText,
  inputProps,
  ...rest
}: IInputProps) => {
  return (
    <FormControl {...rest}>
      <FormLabel fontSize="sm" fontWeight="semibold">
        {label}
      </FormLabel>
      <Field
        as={ChakraInput}
        variant="filled"
        fontSize="sm"
        name={name}
        type={type}
        placeholder={placeholder}
        {...inputProps}
      />
      <FormHelperText>{helperText}</FormHelperText>
      <ErrorMessage name={name}>
        {(message: string) => (
          <Text color="red.600" fontSize="sm">
            {message}
          </Text>
        )}
      </ErrorMessage>
    </FormControl>
  )
}
