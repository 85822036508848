import React from 'react'

import { Button, Text } from '@chakra-ui/react'

import Modal from '@/components/Modal'

import { confirmModalProps } from './types'

const ConfirmModal = ({
  isOpen,
  onClose,
  activeStep,
  setActiveStep,
}: confirmModalProps) => {
  return (
    <Modal
      key="confirm-modal"
      title="Deseja importar esse objeto?"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="sm"
    >
      <Text fontSize="xs">
        Após esse objeto ser importado, ele permanecerá na base do SARE e não
        poderá ser alterado. Se você encontrou alguma inconsistência nos dados,
        por favor, reporte ao FNDE e solicite nova importação.
      </Text>

      <Button
        key="confirm"
        variant="ghost"
        bg="brand.primary.dark_1"
        color="white"
        _hover={{
          bg: 'brand.primary.dark_2',
        }}
        onClick={() => {
          onClose()
          setActiveStep(activeStep + 1)
        }}
      >
        Confirmar
      </Button>
      <Button
        key="go-back"
        color="white"
        bg="brand.error.base"
        _hover={{
          bg: 'brand.error.dark',
        }}
        onClick={() => {
          onClose()
        }}
      >
        Cancelar
      </Button>
    </Modal>
  )
}

export default ConfirmModal
