import React, { useState, useMemo } from 'react'
import { FaChevronLeft, FaCircleChevronRight } from 'react-icons/fa6'
import { IoMdArrowDropdown } from 'react-icons/io'

import { Formik, Form, Field, ErrorMessage, FieldProps } from 'formik'
import * as yup from 'yup'

import {
  Box,
  Button,
  CircularProgress,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react'

import { useQueryGetNoticeObjects } from '@/api/notices/queries'
import { INoticeObjectDTO } from '@/DTOs/notices/noticeDTO'
import { getErrorDetails } from '@/utils/error'

import { ManualImportProps } from './types'

const validationSchema = yup.object().shape({
  notice: yup.string().required('Selecione o edital'),
  object: yup.string().required('Selecione o objeto'),
})

export type SelectOption = {
  value: number
  label: number
}

const ManualImport = ({
  activeStep,
  setActiveStep,
  setData,
}: ManualImportProps) => {
  const toast = useToast()
  const [objectOptions, setObjectOptions] = useState<Array<SelectOption>>([])

  const {
    data: noticeObjects,
    isLoading,
    error,
  } = useQueryGetNoticeObjects({
    onError: error => {
      toast({
        title: 'Erro ao carregar os editais e objetos',
        description: getErrorDetails(error),
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  })

  const noticeOptions = useMemo(() => {
    if (!noticeObjects) return []
    return noticeObjects.map((noticeObject: INoticeObjectDTO) => ({
      value: noticeObject.coNotice,
      label: noticeObject.coNotice,
    }))
  }, [noticeObjects])

  if (isLoading) {
    return (
      <Flex
        direction="column"
        align="center"
        justify="center"
        w="full"
        h="full"
      >
        <CircularProgress size="5rem" thickness="0.25rem" isIndeterminate />
      </Flex>
    )
  }

  const handleNoticeChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    setFieldValue: (field: string, value: string | number) => void
  ) => {
    const noticeValue = e.target.value

    if (!noticeValue) {
      setFieldValue('notice', '')
      setFieldValue('object', '')
      setObjectOptions([])
      return
    }

    const selectedNoticeObject = noticeObjects?.find(
      (noticeObject: INoticeObjectDTO) =>
        noticeObject.coNotice === Number(noticeValue)
    )

    const options =
      selectedNoticeObject?.objects.map(object => ({
        value: object.coObject,
        label: object.coObject,
      })) || []

    setFieldValue('notice', noticeValue)
    setFieldValue('object', '')
    setObjectOptions(options)
  }

  return (
    <Flex
      direction="column"
      align="center"
      justify="space-between"
      w="full"
      h="full"
    >
      <Formik
        initialValues={{ notice: '', object: '' }}
        validationSchema={validationSchema}
        onSubmit={values => {
          setData(values)
          setActiveStep(activeStep + 1)
        }}
      >
        {({ setFieldValue }) => (
          <Form
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
            }}
          >
            <Flex
              direction="column"
              align="center"
              justify="center"
              flex="1"
              w="full"
              maxW="400px"
              gap="8"
              mx="auto"
              py="60px"
            >
              <Text
                color="brand.primary.dark_1"
                textAlign="center"
                fontSize="20px"
                fontWeight="700"
                lineHeight="120%"
              >
                Escolha o edital e objeto
              </Text>

              <Box w="full">
                <FormLabel fontSize="sm">Edital</FormLabel>
                <Field name="notice">
                  {({ field }: FieldProps) => (
                    <FormControl>
                      <Select
                        {...field}
                        icon={<IoMdArrowDropdown />}
                        bg="brand.neutral.light_2"
                        borderColor="brand.neutral.light_2"
                        placeholder="Selecione o edital"
                        _placeholder={{ fontStyle: 'italic' }}
                        onChange={e => handleNoticeChange(e, setFieldValue)}
                        disabled={!noticeObjects?.length || !!error}
                      >
                        {noticeOptions.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Select>
                      <ErrorMessage name="notice">
                        {(message: string) => (
                          <Text
                            color="red.600"
                            fontSize="sm"
                            fontStyle="italic"
                            mt="1"
                          >
                            {message}
                          </Text>
                        )}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Box>

              <Box w="full">
                <FormLabel fontSize="sm">Objeto</FormLabel>
                <Field name="object">
                  {({ field }: FieldProps) => (
                    <FormControl>
                      <Select
                        {...field}
                        icon={<IoMdArrowDropdown />}
                        bg="brand.neutral.light_2"
                        borderColor="brand.neutral.light_2"
                        placeholder="Selecione o objeto"
                        _placeholder={{
                          fontStyle: 'italic',
                          fontcolor: 'gray',
                        }}
                        disabled={!objectOptions.length || !!error}
                      >
                        {objectOptions.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Select>
                      <ErrorMessage name="object">
                        {(message: string) => (
                          <Text
                            color="red.600"
                            fontSize="sm"
                            fontStyle="italic"
                          >
                            {message}
                          </Text>
                        )}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Box>
            </Flex>

            <Flex direction="row" justify="space-between" w="full" p="0" m="0">
              <Button
                size={{ base: 'sm', sm: 'md' }}
                color="brand.primary.dark_1"
                bg="white"
                _hover={{ bg: 'brand.primary.dark_2' }}
                gap="2"
                py="2"
                px="6"
                borderRadius="4"
                leftIcon={<Icon as={FaChevronLeft} />}
                onClick={() => setActiveStep(activeStep - 1)}
              >
                Voltar
              </Button>

              <Button
                size={{ base: 'sm', sm: 'md' }}
                type="submit"
                color="white"
                bg="brand.primary.dark_1"
                _hover={{ bg: 'brand.primary.dark_2' }}
                gap="2"
                py="2"
                px="6"
                borderRadius="4"
                rightIcon={<Icon as={FaCircleChevronRight} />}
              >
                Próximo
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Flex>
  )
}

export default ManualImport
