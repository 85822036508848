import React from 'react'
import { FaChevronLeft, FaCalculator } from 'react-icons/fa'

import { Formik, Form } from 'formik'
import * as yup from 'yup'

import { Stack, Text, Flex, Button } from '@chakra-ui/react'

import { Input } from '@/components/Forms'

import { IEffortCalculatorProps } from './types'

interface IFormValues {
  time: number | string
  workload: number | string
}

const EffertCalculator = ({
  goToPrevious,
  goToNext,
}: IEffortCalculatorProps) => {
  const validationSchema = yup.object().shape({
    time: yup.number().required('Tempo de análise obrigatório'),
    workload: yup.number().required('Carga horária obrigatório'),
  })

  const handleSubmit = (values: IFormValues) => {
    goToNext?.()
  }

  return (
    <Stack gap={4}>
      <Stack gap={3}>
        <Text fontWeight="bold" fontSize="xl" color="brand.primary.dark_1">
          Calculadora de Dimensionamento das Equipes
        </Text>
        <Text fontSize="xs">
          A calculadora de dimensionamento quantifica o esforço necessário para
          as equipes avaliarem os volumes que serão distribuÍdos. <br /> Nesse
          sentido, adicione as informações necessárias para realizar o cálculo:
        </Text>
      </Stack>
      <Formik
        initialValues={{
          time: '',
          workload: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Stack gap={4}>
            <Flex gap={4}>
              <Input
                name="time"
                label="Tempo de Análise do Objeto"
                type="number"
                placeholder="Dias úteis"
                helperText="Dias úteis"
                flex={1}
              />
              <Input
                name="workload"
                label="Carga Horária do Analista"
                type="number"
                placeholder="Horas semanais"
                helperText="Horas semanais"
                flex={1}
              />
            </Flex>
            <Flex justify="space-between">
              <Button
                size="sm"
                key="go-back"
                variant="ghost"
                leftIcon={<FaChevronLeft />}
                color="brand.primary.dark_1"
                onClick={goToPrevious}
              >
                Voltar
              </Button>
              <Button
                type="submit"
                size="sm"
                colorScheme="blue"
                bg="brand.primary.dark_1"
                color="white"
                leftIcon={<FaCalculator />}
              >
                Calcular
              </Button>
            </Flex>
          </Stack>
        </Form>
      </Formik>
    </Stack>
  )
}

export default EffertCalculator
