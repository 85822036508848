import React from 'react'
import { useState } from 'react'

import { Box, Flex, Text } from '@chakra-ui/react'

import Stepper from '@/components/Stepper'

import AutomaticImport from './parts/AutomaticImport'
import ManualImport from './parts/ManualImport'
import SelectImport from './parts/SelectImport'
import VerifyImport from './parts/VerifyImport'

const ImportObjects = () => {
  const steps = [
    { title: 'Passo 1', description: 'Modo de Importação' },
    { title: 'Passo 2', description: 'Seleção do Objeto' },
    { title: 'Passo 3', description: 'Importação do Objeto' },
  ]

  const [data, setData] = useState<any>()
  const [activeStep, setActiveStep] = useState(0)
  const [selectedType, setSelectedType] = useState<'manual' | 'automatic'>(
    'manual'
  )

  const handleLastStep = () => {
    // To-Do: Redirecionar para página de objetos com o alerta de sucesso
    window.location.reload()
  }

  const renderImportComponent = () => {
    if (selectedType === 'manual') {
      return (
        <ManualImport
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          setData={setData}
        />
      )
    } else {
      return (
        <AutomaticImport
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          setData={setData}
        />
      )
    }
  }

  return (
    <Flex
      direction="column"
      flexGrow={1}
      borderRadius="md"
      boxShadow="md"
      p="5"
      w="full"
      h="full"
    >
      <Text
        color="brand.primary.dark_1"
        fontSize="20px"
        fontWeight="700"
        lineHeight="120%"
      >
        Importação de Objetos
      </Text>
      <Stepper steps={steps} onLastStep={handleLastStep} index={activeStep}>
        <SelectImport
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          setSelectedType={setSelectedType}
        />
        {renderImportComponent()}
        <VerifyImport
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          data={data}
        />
      </Stepper>
    </Flex>
  )
}

export default ImportObjects
