import {
  styled,
  ModalHeader as ChakraModalHeader,
  ModalBody as ChakraModalBody,
} from '@chakra-ui/react'

const ModalHeader = styled(ChakraModalHeader, {
  baseStyle: {
    color: 'brand.neutral.dark_2',
    fontSize: 'xl',
    fontWeight: 'semibold',
    lineHeight: '150%',
    borderBottom: '1px solid',
    paddingBottom: 0,
  },
})

const ModalBody = styled(ChakraModalBody, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    paddingY: 6,
    gap: 4,
  },
})

export default {
  ModalHeader,
  ModalBody,
}
