import {
  Flex,
  FormControl,
  Avatar,
  Box,
  FormLabel,
  Input,
  Text,
  styled,
  Button,
} from '@chakra-ui/react'

const UserDetailsContainer = styled(Flex, {
  baseStyle: {
    display: 'flex',
    padding: '24px 48px 48px 48px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    flex: '1 0 0',
    alignSelf: 'stretch',
  },
})

const StyledForm = styled(FormControl, {
  baseStyle: {
    display: 'flex',
    padding: '20px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    borderRadius: '5px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
})

const TopContainer = styled(Box, {
  baseStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '24px',
    alignSelf: 'stretch',
    position: 'relative',
  },
})

const StyledAvatar = styled(Avatar, {
  baseStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    gap: '-80px',
    width: '160px',
    height: '160px',
    borderRadius: '80px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'lightgray',
  },
})

const UserInfo = styled(Box, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
})

const UserNameText = styled(Text, {
  baseStyle: {
    fontFamily: 'Rawline',
    fontSize: 'xl',
    fontWeight: '700',
    lineHeight: '120%',
  },
})

const UserEmailText = styled(Text, {
  baseStyle: {
    fontFamily: 'Rawline',
    fontSize: 'md',
    fontWeight: '400',
    lineHeight: '150%',
  },
})

const Row = styled(Flex, {
  baseStyle: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '16px',
    alignSelf: 'stretch',
    marginBottom: '16px',
    flexDirection: {
      base: 'column',
      sm: 'row',
    },
  },
})

const StyledColumn = styled(Box, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    width: '100%',
  },
})

const StyledInput = styled(Input, {
  baseStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderRadius: '6px',
    border: '1px solid',
    '::placeholder': {
      fontFamily: 'Rawline',
      fontSize: 'md',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '24px',
    },
  },
})

const StyledFormLabel = styled(FormLabel, {
  baseStyle: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '4px',
    alignSelf: 'stretch',
  },
})

const LabelText = styled(Text, {
  baseStyle: {
    fontFamily: 'Rawline',
    fontSize: 'md',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '150%',
  },
})

const HeaderText = styled(Text, {
  baseStyle: {
    fontFamily: 'Rawline',
    fontSize: 'lg',
    fontWeight: '400',
    lineHeight: '150%',
    gap: '30px',
  },
})

const InfoText = styled(Text, {
  baseStyle: {
    fontFamily: 'Rawline',
    fontSize: 'sm',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '150%',
    marginTop: '8px',
  },
})

const FormButtonsContainer = styled(Flex, {
  baseStyle: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    gap: '8px',
    alignSelf: 'stretch',
  },
})

const SaveChangesButton = styled(Button, {
  baseStyle: {
    display: 'flex',
    height: '32px',
    padding: '0px 12px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '6px',
    fontFamily: 'Rawline',
    fontSize: 'sm',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '120%',
  },
})

const BackButton = styled(Button, {
  baseStyle: {
    height: '32px',
    fontFamily: 'Rawline',
    fontSize: 'sm',
    fontWeight: '600',
    lineHeight: '120%',
  },
})

const InstitutionColumn = styled(StyledColumn, {
  flex: '1 0 0',
})

const PositionColumn = styled(Box, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '251px',
  },
})

const UnlinkButton = styled(Button, {
  baseStyle: {
    display: 'flex',
    padding: '0px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    flex: '1 0 0',
    borderRadius: '6px',
    border: '1px solid',
    fontFamily: 'Rawline',
    fontSize: 'md',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '120%',
    background: 'transparent',
  },
})

const RenewButton = styled(Button, {
  baseStyle: {
    display: 'flex',
    padding: '0px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    flex: '1 0 0',
    borderRadius: '6px',
    border: '1px solid',
    fontFamily: 'Rawline',
    fontSize: 'md',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '120%',
    background: 'transparent',
  },
})

const EqualWidthRow = styled(Row, {
  baseStyle: {
    justifyContent: 'space-between',
    alignItems: 'end',
  },
})

const EqualWidthColumn = styled(StyledColumn, {
  baseStyle: {
    flex: '1',
  },
})

const HighContrastIcon = styled(Box, {
  baseStyle: {
    width: '12px',
    height: '12px',
    flexShrink: '0',
    fill: 'white',
  },
})

const StyledDateInput = styled(Input, {
  baseStyle: {
    '::placeholder': {
      fontFamily: 'Rawline',
      fontSize: 'md',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '24px',
    },
  },
})

const EditAvatarButton = styled(Button, {
  baseStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px',
    gap: '10px',
    borderRadius: '20px',
    position: 'absolute',
    bottom: '0px',
    right: '0px',
  },
})

const EditAvatarIcon = styled(Box, {
  baseStyle: {
    width: '16px',
    height: '16px',
    flexShrink: '0',
  },
})

const AvatarContainer = styled(Box, {
  baseStyle: {
    position: 'relative',
    display: 'inline-block',
  },
})

export default {
  UserDetailsContainer,
  StyledForm,
  TopContainer,
  StyledAvatar,
  UserInfo,
  UserNameText,
  UserEmailText,
  Row,
  StyledColumn,
  StyledInput,
  StyledFormLabel,
  LabelText,
  HeaderText,
  InfoText,
  FormButtonsContainer,
  SaveChangesButton,
  BackButton,
  InstitutionColumn,
  PositionColumn,
  UnlinkButton,
  RenewButton,
  EqualWidthRow,
  EqualWidthColumn,
  HighContrastIcon,
  StyledDateInput,
  EditAvatarButton,
  EditAvatarIcon,
  AvatarContainer,
}
