import React from 'react'
import InputMask from 'react-input-mask'

import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { Box, Input, Select, Text } from '@chakra-ui/react'

import { useProfileContext } from '@/contexts/ProfileContext/ProfileContext'

import S from './styles'
const PersonalInformation: React.FC = () => {
  const {
    userData,
    dataMyAccount,
    isAdmin,
    isLoadingSpecialNeeds,
    isErrorSpecialNeeds,
    specialNeeds,
    countries,
    states,
    cities,
    setSelectedCountry,
    setSelectedState,
    setSelectedCity,
    selectedCountry,
    selectedState,
    selectedCity,
    onSubmit,
    submitFormikRef,
    dataLvlSchool,
    isLoadingCities,
    isLoadingStates,
  } = useProfileContext()

  const validationSchema = Yup.object({
    dsInstitutionalEmail: Yup.string()
      .email('E-mail inválido')
      .required('Obrigatório'),
    coSpecialNeed: Yup.string().required('Obrigatório'),
    coEducationLevel: Yup.string().required('Obrigatório'),
    selectedCountry: Yup.string().required('Obrigatório'),
    selectedState: Yup.string().required('Obrigatório'),
    selectedCity: Yup.string().required('Obrigatório'),
  })

  const initialValues = {
    noUserName: userData?.noUserName || '',
    noUserSocialName: dataMyAccount?.noUserSocialName || '',
    dsCpf: dataMyAccount?.dsCpf || '',
    dtBirthDate: dataMyAccount?.dtBirthDate || '',
    coSpecialNeed: userData?.coSpecialNeed || '',
    coEducationLevel: userData?.coEducationLevel || '',
    dsInstitutionalEmail: userData?.ds_institutional_email || '',
    dsGovbrEmail: userData?.dsGovbrEmail || '',
    dsCellphone: dataMyAccount?.dsCellphone || '',
    selectedCountry: selectedCountry || '',
    selectedState: selectedState || '',
    selectedCity: selectedCity || '',
  }

  console.log('initialValues', initialValues)

  return (
    <Formik
      innerRef={submitFormikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Box width="100%">
            <S.Row>
              <S.StyledColumn>
                <S.StyledFormLabel>
                  <S.LabelText>Nome</S.LabelText>
                </S.StyledFormLabel>
                <S.StyledInput
                  placeholder="Nome"
                  value={userData?.noUserName || ''}
                  required
                  disabled={true}
                />
              </S.StyledColumn>

              <S.StyledColumn>
                <S.StyledFormLabel>
                  <S.LabelText>Nome Social</S.LabelText>
                </S.StyledFormLabel>
                <Field
                  as={S.StyledInput}
                  name="noUserSocialName"
                  placeholder="Digite seu nome social"
                  value={dataMyAccount?.noUserSocialName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('noUserSocialName', e.target.value)
                  }
                />
              </S.StyledColumn>
            </S.Row>
            <S.Row>
              <S.StyledColumn>
                <S.StyledFormLabel>
                  <S.LabelText>CPF</S.LabelText>
                </S.StyledFormLabel>
                <Input
                  placeholder="00.000.000-00"
                  value={dataMyAccount?.dsCpf || ''}
                  readOnly={isAdmin}
                  disabled={true}
                  required
                  as={InputMask}
                  variant="filled"
                  mask="99.999.999-99"
                  bg={'#F8F8F8'}
                />
              </S.StyledColumn>
              <S.StyledColumn>
                <S.StyledFormLabel>
                  <S.LabelText>Data de nascimento</S.LabelText>
                </S.StyledFormLabel>
                <S.StyledInput
                  type="date"
                  value={dataMyAccount?.dtBirthDate || ''}
                  readOnly={isAdmin}
                  disabled={isAdmin}
                />
                <S.InfoText>Obrigatório</S.InfoText>
              </S.StyledColumn>
            </S.Row>
            <S.Row>
              <S.StyledColumn>
                <S.StyledFormLabel>
                  <S.LabelText>Deficiência</S.LabelText>
                </S.StyledFormLabel>
                <Field
                  as={Select}
                  name="coSpecialNeed"
                  value={values.coSpecialNeed}
                  width="100%"
                  background={'#F8F8F8'}
                  borderColor={'transparent'}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    console.log('e.target.value', e.target.value)
                    setFieldValue('coSpecialNeed', e.target.value)
                  }}
                >
                  <option value="">Selecione uma opção</option>
                  {!isLoadingSpecialNeeds &&
                    !isErrorSpecialNeeds &&
                    specialNeeds?.map(
                      (need: {
                        coSpecialNeed: string
                        dsSpecialNeed: string
                      }) => (
                        <option
                          key={need.coSpecialNeed}
                          value={need.coSpecialNeed}
                        >
                          {need.dsSpecialNeed}
                        </option>
                      )
                    )}
                </Field>
                <ErrorMessage name="coSpecialNeed">
                  {(message: string) => (
                    <Text color="red.600" fontSize="sm">
                      {message}
                    </Text>
                  )}
                </ErrorMessage>
              </S.StyledColumn>
              <S.StyledColumn>
                <S.StyledFormLabel>
                  <S.LabelText>Nível de Escolaridade</S.LabelText>
                </S.StyledFormLabel>
                <Field
                  as={Select}
                  name="coEducationLevel"
                  value={values.coEducationLevel}
                  width="100%"
                  background={'#F8F8F8'}
                  borderColor={'transparent'}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    console.log('e.target.value', e.target.value)
                    setFieldValue('coEducationLevel', e.target.value)
                  }}
                >
                  <option value="">Selecione uma opção</option>
                  {dataLvlSchool?.map(
                    (data: { value: string; label: string }) => (
                      <option key={data.value} value={data.value}>
                        {data.label}
                      </option>
                    )
                  )}
                </Field>
                <ErrorMessage name="coEducationLevel">
                  {(message: string) => (
                    <Text color="red.600" fontSize="sm">
                      {message}
                    </Text>
                  )}
                </ErrorMessage>
              </S.StyledColumn>
            </S.Row>
            <S.Row>
              <S.HeaderText>Contatos</S.HeaderText>
            </S.Row>
            <S.Row>
              <S.StyledColumn>
                <S.StyledFormLabel>
                  <S.LabelText>E-mail institucional</S.LabelText>
                </S.StyledFormLabel>
                <Field
                  as={S.StyledInput}
                  type="email"
                  name="dsInstitutionalEmail"
                  placeholder="nome@nees.ufal.br"
                  value={values.dsInstitutionalEmail}
                  required
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('dsInstitutionalEmail', e.target.value)
                  }
                />
                <ErrorMessage name="dsInstitutionalEmail">
                  {(message: string) => (
                    <Text color="red.600" fontSize="sm">
                      {message}
                    </Text>
                  )}
                </ErrorMessage>
                <S.InfoText>
                  Escreva aqui o e-mail que deseja receber as notificações do
                  sare.
                </S.InfoText>
              </S.StyledColumn>
              <S.StyledColumn>
                <S.StyledFormLabel>
                  <S.LabelText>E-mail do gov.br</S.LabelText>
                </S.StyledFormLabel>
                <S.StyledInput
                  type="email"
                  placeholder="nome@email.com.br"
                  value={userData?.dsGovbrEmail || ''}
                  readOnly={isAdmin}
                  disabled={true}
                />
                {isAdmin ? (
                  <S.InfoText>
                    Esse campo é preenchido automaticamente pelo govbr e não
                    pode ser alterado.
                  </S.InfoText>
                ) : (
                  <></>
                )}
              </S.StyledColumn>
            </S.Row>
            <S.Row>
              <S.StyledColumn>
                <S.StyledFormLabel>
                  <S.LabelText>Telefone</S.LabelText>
                </S.StyledFormLabel>
                <Input
                  type="tel"
                  placeholder="82 99999-9999"
                  value={dataMyAccount?.dsCellphone || ''}
                  as={InputMask}
                  variant="filled"
                  mask={'99 99999-9999'}
                  bg={'#F8F8F8'}
                />
              </S.StyledColumn>
            </S.Row>
            <S.Row>
              <S.HeaderText>Endereço</S.HeaderText>
            </S.Row>
            <S.Row>
              <S.StyledColumn>
                <S.StyledFormLabel>
                  <S.LabelText>País</S.LabelText>
                </S.StyledFormLabel>
                <Select
                  placeholder="Selecione um país"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setSelectedCountry(e.target.value)
                    setFieldValue('selectedCountry', e.target.value)
                  }}
                  background={'#F8F8F8'}
                  borderColor={'transparent'}
                >
                  {countries.map((country: string, index: number) => (
                    <option key={`country-${country}-${index}`} value={country}>
                      {country}
                    </option>
                  ))}
                </Select>
                <ErrorMessage name="selectedCountry">
                  {(message: string) => (
                    <Text color="red.600" fontSize="sm">
                      {message}
                    </Text>
                  )}
                </ErrorMessage>
              </S.StyledColumn>
              <S.StyledColumn>
                <S.StyledFormLabel>
                  <S.LabelText>Estado</S.LabelText>
                </S.StyledFormLabel>
                <Select
                  placeholder={
                    isLoadingStates ? 'Carregando...' : 'Selecione um estado'
                  }
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setSelectedState(e.target.value)
                    setFieldValue('selectedState', e.target.value)
                  }}
                  isDisabled={!selectedCountry}
                  background={'#F8F8F8'}
                  borderColor={'transparent'}
                >
                  {states.map((state: string) => (
                    <option key={`state-${state}`} value={state}>
                      {state}
                    </option>
                  ))}
                </Select>
                <ErrorMessage name="selectedState">
                  {(message: string) => (
                    <Text color="red.600" fontSize="sm">
                      {message}
                    </Text>
                  )}
                </ErrorMessage>
              </S.StyledColumn>
              <S.StyledColumn>
                <S.StyledFormLabel>
                  <S.LabelText>Cidade</S.LabelText>
                </S.StyledFormLabel>
                <Select
                  placeholder={
                    isLoadingCities ? 'Carregando...' : 'Selecione uma cidade'
                  }
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setSelectedCity(e.target.value)
                    setFieldValue('selectedCity', e.target.value)
                  }}
                  isDisabled={!selectedState}
                  background={'#F8F8F8'}
                  borderColor={'transparent'}
                >
                  {cities.map((city: string) => (
                    <option
                      key={`city-${city}`}
                      value={city}
                      defaultValue={selectedCity}
                    >
                      {city}
                    </option>
                  ))}
                </Select>
                <ErrorMessage name="selectedCity">
                  {(message: string) => (
                    <Text color="red.600" fontSize="sm">
                      {message}
                    </Text>
                  )}
                </ErrorMessage>
              </S.StyledColumn>
            </S.Row>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default PersonalInformation
