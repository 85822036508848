interface IParseCSVProps {
  file: File
  requiredColumns?: Array<string>
}

/**
 * @description Function to parse a CSV file and convert it into an array of objects.
 * @param {IParseCSVProps} props - An object containing the CSV file and optional required columns.
 * @param {File} props.file - The CSV file to parse.
 * @param {Array<string>} [props.requiredColumns=[]] - An optional array of column names that must be present in the CSV file.
 * @returns {Promise<Array<Record<string, string>>>} - A promise that resolves with an array of objects representing the CSV data.
 */
export const parseCSV = ({
  file,
  requiredColumns = [],
}: IParseCSVProps): Promise<Array<Record<string, string>>> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = e => {
      const csv = e.target?.result as string
      const rows = csv.split('\n')
      const headers = rows[0].split(',').map(h => h.trim().toLowerCase())

      for (const column of requiredColumns) {
        if (!headers.includes(column)) {
          reject(
            new Error(
              `O arquivo ${file.name} não contém a coluna obrigatória: ${column}`
            )
          )
          return
        }
      }

      const data = rows.slice(1).map(row => {
        const values = row.split(',')
        return headers.reduce((acc, header, i) => {
          acc[header.toLowerCase()] = values[i]
          return acc
        }, {} as Record<string, string>)
      })

      if (Object.values(data[data.length - 1]).every(v => v === '')) {
        data.pop()
      }

      resolve(data)
    }
    reader.onerror = e => {
      reject(e)
    }
    reader.readAsText(file)
  })
}
