import React from 'react'

import {
  Modal as ChakraModal,
  ModalCloseButton,
  ModalOverlay,
  ModalContent,
} from '@chakra-ui/react'

import S from './styles'
import { IModalProps } from './types'

function Modal({ title, isOpen, onClose, children, ...rest }: IModalProps) {
  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent>
        <S.ModalHeader borderBottomColor="transparent">{title}</S.ModalHeader>
        <ModalCloseButton data-testid="modal-close-button" />
        <S.ModalBody>{children}</S.ModalBody>
      </ModalContent>
    </ChakraModal>
  )
}

export default Modal
