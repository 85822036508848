import React, { useMemo, useState } from 'react'
import { FaMinusCircle } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'

import { EditIcon } from '@chakra-ui/icons'
import {
  Flex,
  Button,
  IconButton,
  useColorModeValue,
  Text,
  useDisclosure,
  useColorMode,
  VStack,
  Box,
} from '@chakra-ui/react'
import { RowData } from '@tanstack/react-table'

import DataFilter, { IFilter } from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'
import Tabs from '@/components/Tabs'
import StatusTag from '@/components/Tag/StatusCardTeam'

import { initialCards, filterExample, tableHeaders } from './exampleData'
import DeleteTeamModal from './parts/DeleteTeamModal'

// Aguardando objetos

const Team = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const initialPath = location.pathname.split('/').pop()

  const {
    isOpen: isDeleteTeamOpen,
    onOpen: onDeleteTeamOpen,
    onClose: onDeleteTeam,
  } = useDisclosure()
  const [selectedTeams, setSelectedTeams] = useState<Array<RowData>>([])
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 5,
    itemsCount: 0,
    pageCount: 1,
    pageSizeOptions: [10, 20, 30, 40, 50],
  })

  const handleOnChangePageInfo = ({
    pageIndex,
    itemsPerPage,
  }: IPageInfoProps) => {
    const previousItemsPerPage = pagination.pageSize
    const adjustedPageIndex =
      itemsPerPage !== previousItemsPerPage ? 1 : pageIndex + 1

    setPagination({
      ...pagination,
      page: adjustedPageIndex,
      pageSize: itemsPerPage,
    })
  }

  const handleDeleteTeam = () => {
    onDeleteTeamOpen()
  }

  const handleTabClick = (id: string) => {
    navigate(`/configuracoes/${id}`)
  }

  const tabs = [
    {
      id: 'usuario',
      label: 'Usuário',
    },
    {
      id: 'equipe',
      label: 'Equipe',
    },
    {
      id: 'permissoes',
      label: 'Permissões',
    },
  ]

  const teamsDataTable = useMemo(() => {
    const teams = initialCards ? initialCards : []

    const mappedTeams = teams.map(team => {
      const teamInfo = (title: string, description: string) => (
        <VStack justify="flex-start">
          <Box w="full">
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              {title}
            </Text>
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              {description}
            </Text>
          </Box>
        </VStack>
      )

      const supervisorInfo = (supervisor: string, supervisorInfo: string) => (
        <VStack justify="flex-start">
          <Box w="full">
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              {supervisor}
            </Text>
            <Text fontSize="sm" color="brand.neutral.dark_2" textAlign="start">
              {supervisorInfo}
            </Text>
          </Box>
        </VStack>
      )

      const editButton = (id: string) => {
        const handleEdit = () => {
          navigate(`/configuracoes/equipe/detalhes/${id}`)
        }

        return (
          <IconButton
            aria-label="Editar"
            variant="ghost"
            size="sm"
            icon={<EditIcon />}
            color="brand.primary.dark_1"
            onClick={() => handleEdit()}
          />
        )
      }

      return {
        user: teamInfo(team.title, team.description),
        date: team.date,
        supervisor: supervisorInfo(
          team.supervisor.label,
          team.supervisor.value
        ),
        status: StatusTag(team.status),
        edit: editButton(team.id),
        status_code: team.status,
      }
    })
    return mappedTeams
  }, [initialCards])

  return (
    <>
      <Tabs items={tabs} initialTab={initialPath} onTabClick={handleTabClick} />
      <Flex direction="column" gap={6}>
        <Box p={4} boxShadow="md">
          <DataFilter
            testId="teams-filter"
            filters={filterExample}
            onChangeFilter={() => {}}
          />
        </Box>
        <Flex
          width="full"
          p={5}
          borderRadius="lg"
          borderWidth={1}
          boxShadow="md"
          flexDirection={'column'}
          gap={4}
          bg="white"
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            flexDirection={{
              base: 'column',
              md: 'row',
              lg: 'row',
            }}
          >
            <Text
              fontFamily="Roboto"
              fontSize="xl"
              fontWeight="700"
              color="brand.primary.dark_1"
              lineHeight="26px"
            >
              Configurações de Equipes
            </Text>
            <Button
              leftIcon={<FaMinusCircle size="14px" color="white" />}
              size="sm"
              fontSize="sm"
              fontWeight="semibold"
              backgroundColor="brand.error.base"
              color="white"
              _hover={{ bg: 'brand.error.dark' }}
              onClick={handleDeleteTeam}
            >
              Excluir equipes
            </Button>
          </Flex>
          <DataTable
            headers={tableHeaders}
            data={teamsDataTable}
            isLoading={false}
            selectableRow
            onRowSelectionChange={rows => setSelectedTeams(rows)}
          />
          <Pagination
            pageIndex={pagination.page - 1}
            optionsItemsPerPage={pagination.pageSizeOptions}
            itemsPerPage={pagination.pageSize}
            pageCount={pagination.pageCount}
            itemsCount={pagination.itemsCount}
            onChangePageInfo={handleOnChangePageInfo}
          />
        </Flex>
      </Flex>
      <DeleteTeamModal
        teams={selectedTeams}
        isOpen={isDeleteTeamOpen}
        onClose={onDeleteTeam}
      />
    </>
  )
}

export default Team
