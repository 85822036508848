import { extendTheme } from '@chakra-ui/react'
import { Dict } from '@chakra-ui/utils'

import colors from './foundations/colors'
import fonts from './foundations/fonts'
import styles from './styles'

export * from './types'

export const theme: Dict = extendTheme({
  styles,
  colors,
  fonts,
})

export default theme
