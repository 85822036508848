import { useRef, useState } from 'react'

import { FormikProps } from 'formik'

import { useToast } from '@chakra-ui/react'

import {
  useQueryGetCities,
  useQueryGetCountries,
  useQueryGetStates,
} from '@/api/address/queries'
import { useMutationEditMyAccount } from '@/api/user/management/mutations'
import { useQueryMe, useQueryMyAccount } from '@/api/user/management/queries'
import { useQueryGetSpecialNeeds } from '@/api/user/specialNeeds/queries'

export const useProfileController = () => {
  const toast = useToast()
  const [selectedState, setSelectedState] = useState('')
  const [selectedCountry, setSelectedCountry] = useState('')

  const [selectedCity, setSelectedCity] = useState('')
  const [isOpened, setIsOpened] = useState(false)

  const { mutate: editMyAccount } = useMutationEditMyAccount({
    onSuccess: () => {
      toast({
        title: 'Perfil atualizado com sucesso',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    },
    onError: error => {
      toast({
        title: 'Erro ao atualizar perfil',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    },
  })

  const { data: userData } = useQueryMe({
    onError: () => {
      toast({
        title: 'Erro ao buscar usuário',
        description: 'Ocorreu um erro ao buscar o usuário.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    },
  })

  const { data: dataMyAccount } = useQueryMyAccount(
    {
      coUser: `${userData?.coUser}`,
    },
    {
      onError: () => {
        toast({
          title: 'Erro ao buscar usuário',
          description: 'Ocorreu um erro ao buscar o usuário.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      },
    }
  )

  const {
    data: specialNeeds,
    isLoading: isLoadingSpecialNeeds,
    isError: isErrorSpecialNeeds,
  } = useQueryGetSpecialNeeds()

  const { data: countries = [], isLoading: isLoadingCountries } =
    useQueryGetCountries({
      onError: () => {
        toast({
          title: 'Erro ao buscar países',
          description: 'Ocorreu um erro ao buscar os países.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      },
    })
  const { data: states = [], isLoading: isLoadingStates } = useQueryGetStates(
    {
      country: selectedCountry,
    },
    {
      onError: () => {
        if (selectedCountry === '') return
        toast({
          title: 'Erro ao buscar estados',
          description: 'Ocorreu um erro ao buscar os estados.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      },
    }
  )
  const { data: cities = [], isLoading: isLoadingCities } = useQueryGetCities(
    {
      uf: selectedState,
      country: selectedCountry,
    },
    {
      onError: () => {
        if (selectedState === '') return
        toast({
          title: 'Erro ao buscar cidades',
          description: 'Ocorreu um erro ao buscar as cidades.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      },
    }
  )

  const onSubmit = (values: {
    special_needs: { value: string; label: string }[]
    selectedCity: string
    selectedCountry: string
    selectedState: string
    birth_date: string
    dtBirthDate: string
    dsCellphone: string
  }) => {
    editMyAccount({
      coUser: userData?.coUser,
      params: {
        ...values,
        no_user_name: userData?.noUserName || '',
        ds_cellphone: values.dsCellphone || dataMyAccount?.ds_cellphone,
        dt_birth_date: values.dtBirthDate
          ? values.dtBirthDate
          : dataMyAccount?.dt_birth_date,
        special_needs: values.special_needs
          ? values.special_needs.map(
              (item: { value: string; label: string }) => item.value
            )
          : [],
        ds_uf_province: values.selectedState ? values.selectedState : null,
        ds_city: values.selectedCity ? values.selectedCity : null,
        ds_country: values.selectedCountry ? values.selectedCountry : null,
      },
    })
  }
  const dataLvlSchool = [
    { value: 1, label: 'Fundamental Completo' },
    { value: 2, label: 'Médio Incompleto' },
    { value: 3, label: 'Médio Completo' },
    { value: 4, label: 'Superior Incompleto' },
    { value: 5, label: 'Superior Completo' },
    { value: 6, label: 'Pós-graduação' },
  ]
  const isAdmin = userData?.ncoProfile[0] === 1
  const submitFormikRef = useRef<FormikProps<any>>(null)
  const handleOnSubmit = () => {
    console.log('submitFormikRef', submitFormikRef)
    if (submitFormikRef.current) {
      submitFormikRef.current.handleSubmit()
    }
  }

  return {
    userData,
    dataMyAccount,
    isAdmin,
    isLoadingSpecialNeeds,
    isErrorSpecialNeeds,
    specialNeeds,
    countries,
    states,
    cities,
    setSelectedCountry,
    setSelectedState,
    setSelectedCity,
    isOpened,
    setIsOpened,
    selectedCity,
    selectedCountry,
    selectedState,
    handleOnSubmit,
    onSubmit,
    submitFormikRef,
    dataLvlSchool,
    isLoadingCities,
    isLoadingStates,
  }
}
