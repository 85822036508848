import { IHeader } from '@/components/DataTable/types'

export const dataToTable = [
  {
    editora: 'Vitrine Editora Ltda',
    cod_colecao: '0365 P22 02 02 000 000',
    formato: null,
    impresso: '1',
    pdf: '1',
    video: '1',
    audio: '1',
    video_tutorial: '1',
  },
  {
    editora: 'Vitrine Editora Ltda',
    cod_colecao: '0365 P22 02 02 000 001',
    formato: null,
    impresso: '1',
    pdf: '1',
    video: '1',
    audio: '1',
    video_tutorial: '1',
  },
  {
    editora: 'Vitrine Editora Ltda',
    cod_colecao: '0365 P22 02 02 000 002',
    formato: null,
    impresso: '1',
    pdf: '1',
    video: '1',
    audio: '1',
    video_tutorial: '1',
  },
  {
    editora: 'Vitrine Editora Ltda',
    cod_colecao: '0365 P22 02 02 000 003',
    formato: null,
    impresso: '1',
    pdf: '1',
    video: '1',
    audio: '1',
    video_tutorial: '1',
  },
]

export const tableHeaders: Array<IHeader> = [
  {
    name: 'Editora',
    key: 'editora',
    type: 'element',
    width: 'auto',
  },
  {
    name: 'Código de Coleção',
    key: 'cod_colecao',
    type: 'element',
    width: 'auto',
  },
  {
    name: 'Exemplar',
    key: 'exemplar',
    type: 'element',
    width: 'auto',
    subHeader: [
      {
        name: 'Formato',
        key: 'formato',
        type: 'element',
        width: 'auto',
      },
    ],
  },
  {
    name: 'Livro do Estudante',
    key: 'livro_estudante',
    type: 'element',
    width: 'auto',
    subHeader: [
      {
        name: 'Impresso',
        key: 'impresso',
        type: 'element',
        width: 'auto',
      },
      {
        name: 'PDF',
        key: 'pdf',
        type: 'element',
        width: 'auto',
      },
      {
        name: 'Vídeo',
        key: 'video',
        type: 'element',
        width: 'auto',
      },
      {
        name: 'Áudio',
        key: 'audio',
        type: 'element',
        width: 'auto',
      },
      {
        name: 'Vídeo Tutorial',
        key: 'video_tutorial',
        type: 'element',
        width: 'auto',
      },
    ],
  },
]
